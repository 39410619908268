import axios from "axios";
import {getUser} from "./Session";

// const Url = 'https://stagingapi.notenest.com/';
// const Url = 'http://localhost:8000/';
const Url ='https://api.notenest.com/';
const baseURL = Url+"api/v1";

async function postRequest(endpoint,postData,token = false, formData = false) { 
    var returnResponse = false;
    const config = {};
    var CancelToken = axios.CancelToken;    
    var cancel;

    if(token) {
        let userData = getUser();
        let headerParams = {
            Authorization: `Bearer ${userData.token}`,
            cancelToken: new CancelToken(function executor(c) {                
                cancel = c;
              })            
        }

        if(formData){
            headerParams = {...headerParams, 'Content-Type': 'multipart/form-data'}            
        }
        config.headers = headerParams
    }

    await axios
    .post(baseURL + endpoint, postData,config)
    .then((response) => {
        if(response.status === 200) {
            returnResponse = response.data;
        }
        // if(response.data.status == true && response.data.message == "success") {
        //     return response.data.data;
        // } else {
        //     alert(response.data.message);
        //     return false;
        // }
    });

    return returnResponse;
}

async function getRequest(endpoint,token = false) {
    var returnResponse = false;

    const config = {};

    if(token) {
        let userData = getUser();
        config.headers = {
            Authorization: `Bearer ${userData.token}`
        }
    }

    await axios
    .get(baseURL + endpoint,config)
    .then((response) => {
        if(response.status === 200) {
            returnResponse = response.data;
        }
        // if(response.data.status == true && response.data.message == "success") {
        //     return response.data.data;
        // } else {
        //     alert(response.data.message);
        //     return false;
        // }
    });

    return returnResponse;
}

export {
    postRequest,
    getRequest, Url
}
